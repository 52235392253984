<template id="">
  <div id="wall">
    <div class="uk-padding uk-padding-remove-bottom uk-margin-top">
      <h3 class="uk-text-background">回答状況一覧</h3>
      <hr class="uk-maring-remove" />
    </div>
    <div
      v-if="emp_list.length > 0"
      class="uk-container uk-flex uk-flex-middle uk-flex-right uk-margin"
    >
      <div class="">
        <label
          class="uk-button uk-button-default"
          style="background-color: rgb(50, 210, 150); color: snow"
          @click="get_answer_status_list()"
        >
          Excelファイルで出力する
        </label>
      </div>
    </div>
    <div class="uk-padding uk-padding-remove-top">
      <table class="bord uk-table uk-table-divider uk-table-hover">
        <thead
          style="
            background: linear-gradient(to top, lightcyan 10%, deepskyblue);
          "
        >
          <tr class="fixed01">
            <th class="uk-text-secondary uk-text-center">従業員番号</th>
            <th class="uk-text-secondary uk-text-center">氏名</th>
            <th class="uk-text-secondary uk-text-center">部署名</th>
            <th class="uk-text-secondary uk-text-center">回答方法</th>
            <th class="uk-text-secondary uk-text-center">回答状況</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="emp_list" v-cloak>
            <tr v-for="(emp, index) in emp_list" :key="index">
              <td>{{ emp.emp_code }}</td>
              <td>{{ emp.name }}</td>
              <td>{{ emp.wp_name }}</td>
              <td>{{ ret_sc_conf_answer(emp.sc_conf_answer) }}</td>
              <td v-if="ret_answer_status(emp.answer_status) == '未回答'">
                <div class="uk-text-danger">未回答</div>
              </td>
              <td v-else-if="ret_answer_status(emp.answer_status) == '回答中'">
                <div class="uk-text-danger">回答中</div>
              </td>
              <td v-else>{{ ret_answer_status(emp.answer_status) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="uk-margin">
      <button
        type="button"
        name="button"
        @click="$router.back()"
        class="uk-button uk-button-default uk-width-1-3"
      >
        &lt;&nbsp;戻る
      </button>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      detail: null,
      email: null,
      pw: null,
      emp_list: [],
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "回答状況一覧";
  },
  computed: {
    ret_sc_conf_answer() {
      return (key) => {
        if (key == 0) {
          return "Web";
        } else if (key == 1) {
          return "アンケート用紙";
        }
      };
    },
    ret_answer_status() {
      return (key) => {
        if (key == 0) {
          return "未回答";
        } else if (key == 1) {
          return "回答中";
        } else if (key == 2) {
          return "回答済";
        }
      };
    },
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.email = this.$store.state.email;
    this.pw = this.$store.state.pass;
    this.get_answer_status();
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    get_answer_status() {
      this.$axios
        .post("/client/client_answer_status", {
          alias_id: this.$store.state.alias_id,
          email: this.email,
          pw: this.pw,
          sc_id: this.$store.state.sc_id,
        })
        .then((response) => {
          if (response.data.flg) {
            this.emp_list = response.data.result;
          } else {
            console.log("エラー");
          }
          this.$store.state.common.sDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.common.sDataLoading = false;
        });
    },
    // 回答状況リストをExcelで出力する
    get_answer_status_list() {
      this.$axios({
        method: "POST",
        url: "/client/get_answer_status_list",
        data: {
          alias_id: this.$store.state.alias_id,
          email: this.email,
          pw: this.pw,
          sc_id: this.$store.state.sc_id,
        },
        responseType: "blob",
        dataType: "binary",
      })
        .then((response) => {
          const serverFileName = response.headers["server-response-file-name"];
          let xlsx_file = response.data;
          let blob = new Blob([xlsx_file], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let filename = "ストレスチェック対象者リスト" + serverFileName;
          const linkEl = document.createElement("a");
          linkEl.href = window.URL.createObjectURL(blob);
          linkEl.setAttribute("download", filename);
          document.body.appendChild(linkEl);
          linkEl.click();

          URL.revokeObjectURL(window.URL.createObjectURL(blob));
          linkEl.parentNode.removeChild(linkEl);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
